<template>
  <div class="about-container">
    <div class="about-top">
      <p class="top-title">来可能学园，听音频小课</p>
      <p class="top-title" style="font-size: 26px;font-weight: normal;margin-top: 20px">家庭教育新体验，容易吸收更爱学</p>
    </div>

    <div class="about-middle">
      <p class="middle-title">为6-12岁孩子打造易吸收的家庭音频小课</p>
      <p class="middle-title-1" style="margin-top: 20px">发挥短音频的优势，高效利用时间，提升孩子知识水平</p>


      <div style="display: flex;flex-direction: row;margin-top: 60px">
        <!--left-->
        <div style="display:flex;flex-direction: column;align-items: center">

          <img src="../assets/about_logo_1.png" style="width: 200px;height: auto;object-fit: contain">
          <p class="middle-title-3">高效利用时间</p>
          <p class="middle-title-2">15分钟音频小课，全天多场景、多时间段灵活安排，<br>自动生成课表</p>

          <img src="../assets/about_logo_3.png" style="width: 200px;height: auto;object-fit: contain;margin-top: 40px">
          <p class="middle-title-3">课后习题巩固</p>
          <p class="middle-title-2">每个学习内容配备简单课后提问，提炼核心知识点，<br>帮助孩子领悟</p>

          <img src="../assets/about_logo_5.png" style="width: 200px;height: auto;object-fit: contain;margin-top: 40px">
          <p class="middle-title-3">学习内容丰富</p>
          <p class="middle-title-2">语文、英语、科学、思维...还有名著欣赏、榜样教育、课堂配套...<br>满足全方面成长需求</p>

        </div>

        <div style="width: 100px"></div>

        <div style="display:flex;flex-direction: column;align-items: center">

          <img src="../assets/about_logo_2.png" style="width: 200px;height: auto;object-fit: contain">
          <p class="middle-title-3">更加容易吸收</p>
          <p class="middle-title-2">单个学习内容均为2-3分钟短音频，更容易让孩子消化，<br>降低知识摄入的门槛</p>

          <img src="../assets/about_logo_4.png" style="width: 200px;height: auto;object-fit: contain;margin-top: 40px">
          <p class="middle-title-3">保护孩子视力</p>
          <p class="middle-title-2">通过音频形式向孩子讲述，避免孩子过度用眼和接受屏幕辐射，<br>保护孩子视力</p>

          <img src="../assets/about_logo_6.png" style="width: 200px;height: auto;object-fit: contain;margin-top: 40px">
          <p class="middle-title-3">灵活排课功能</p>
          <p class="middle-title-2">根据家长所选内容实现自动智能排课，帮助孩子规划学习计划，<br>让学习井然有序、易于坚持</p>

        </div>

      </div>

    </div>


    <div class="about-bottom">
      <div class="bottom-container">
        <div>
          <p class="top-title">下载可能学园APP，</p>
          <p class="top-title" style="font-size: 32px;margin-top: 8px">给孩子安排TA的专属家庭音频小课吧</p>
          <p class="top-title" style="font-size: 20px;line-height: 32px;margin-top: 30px">
            下载APP即可获得可能学园新人红包，可手机扫右方二维码进入<br>下载页面，也可至各大应用商店下载。</p>
        </div>
        <img src="../assets/about_qrcode.png" style="margin-left: 300px">
      </div>
    </div>


    <div style="padding: 100px 200px;text-align: right">
      <p style="color: #50555c;font-size: 16px;font-family: 'PingFang SC'">可能学园 Able Academy © 2022</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "About"
}
</script>

<style scoped lang="scss">
.about-container {
  width: 100%;
  height: 100%;
}

.about-top {
  width: 100%;
  height: 424px;
  background-image: url("../assets/about_bg_1.jpeg");
  background-repeat: repeat;
  background-size: cover;
  background-color: transparent;
  background-position: 0px -97.1445px;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .top-title {
    color: #626dae;
    font-size: 45px;
    font-weight: bold;
    font-family: "Open Sans", "open_sans", "Lucida Grande", "Verdana", "Pingfang SC", "Pingfang TC", "Hiragino Sans GB", "Microsoft YaHei", Cardo, STSong, "Songti SC", SimSun, "PingFang SC", "Microsoft YaHei", 微软雅黑, STXihei, 华文细黑, serif;;;
  }
}

.about-middle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 100px 0px;

  .middle-title {
    color: #626dae;
    font-size: 36px;
    font-family: "Open Sans", "open_sans", "Lucida Grande", "Verdana", "Pingfang SC", "Pingfang TC", "Hiragino Sans GB", "Microsoft YaHei", Cardo, STSong, "Songti SC", SimSun, "PingFang SC", "Microsoft YaHei", 微软雅黑, STXihei, 华文细黑, serif;;;
  }

  .middle-title-1 {
    color: #333333;
    font-size: 20px;
    font-family: "Open Sans", "open_sans", "Lucida Grande", "Verdana", "Pingfang SC", "Pingfang TC", "Hiragino Sans GB", "Microsoft YaHei", Cardo, STSong, "Songti SC", SimSun, "PingFang SC", "Microsoft YaHei", 微软雅黑, STXihei, 华文细黑, serif;;;
  }

  .middle-title-2 {
    text-align: center;
    margin-top: 30px;
    color: #50555c;
    font-size: 16px;
    line-height: 32px;
    font-family: "Open Sans", "open_sans", "Lucida Grande", "Verdana", "Pingfang SC", "Pingfang TC", "Hiragino Sans GB", "Microsoft YaHei", Cardo, STSong, "Songti SC", SimSun, "PingFang SC", "Microsoft YaHei", 微软雅黑, STXihei, 华文细黑, serif;;;
  }

  .middle-title-3 {
    margin-top: 20px;
    color: #626dae;
    font-size: 24px;
    font-family: "Open Sans", "open_sans", "Lucida Grande", "Verdana", "Pingfang SC", "Pingfang TC", "Hiragino Sans GB", "Microsoft YaHei", Cardo, STSong, "Songti SC", SimSun, "PingFang SC", "Microsoft YaHei", 微软雅黑, STXihei, 华文细黑, serif;;;
  }

}

.about-bottom {
  width: 100%;
  height: 420px;
  background-image: url("../assets/about_bg_2.jpeg");
  background-repeat: no-repeat;
  background-size: 100% 1497px;
  background-color: transparent;
  background-position: 0px -380.546px;
  background-attachment: fixed;


  .bottom-container {
    background-color: rgba(5, 26, 52, 0.35);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .top-title {
      color: #ffffff;
      font-size: 36px;
      font-family: "Open Sans", "open_sans", "Lucida Grande", "Verdana", "Pingfang SC", "Pingfang TC", "Hiragino Sans GB", "Microsoft YaHei", Cardo, STSong, "Songti SC", SimSun, "PingFang SC", "Microsoft YaHei", 微软雅黑, STXihei, 华文细黑, serif;;;
    }
  }
}

</style>
