<template>
  <div class="collection-container">

    <div class="collection-top">
      <p class="top-title">名著主题课程包</p>
      <p class="top-sub-title">足质足量，在家拥有“名著经典宝库”</p>

      <div style="display: flex;flex-direction: row;align-items: center;justify-content: center;margin-top: 50px">
        <div class="top-item">囊括超过90部中外经典名著</div>
        <div style="width: 40px;"></div>
        <div class="top-item">2000多个配有知识点的学习内容</div>
      </div>
      <div style="display: flex;flex-direction: row;align-items: center;justify-content: center;margin-top: 30px">
        <div class="top-item">20个主题课程包</div>
        <div style="width: 40px;"></div>
        <div class="top-item">由浅入深共设4个大级别</div>
      </div>

    </div>
    <div class="line-shadow"></div>
    <div class="first-part">
      <p class="first-title">主题课程包分级</p>
      <div style="display: flex;flex-direction: row;margin-top: 50px;align-items: flex-start">
        <div style="display: flex;flex-direction: column;align-items: center;justify-content: center">
          <p class="first-index">1</p>
          <div class="first-line"></div>
        </div>
        <div style="display: flex;flex-direction: column;margin-left: 25px">
          <p class="first-title-1">第一级：培养兴趣</p>
          <p class="first-sub-title">跟着人物主角去探索，体验充满想象、哲理、智慧和关爱的新<br>奇世界。</p>
        </div>
      </div>
      <div style="display: flex;flex-direction: row;margin-top: 30px;align-items: flex-start">
        <div style="display: flex;flex-direction: column;align-items: center;justify-content: center">
          <p class="first-index">2</p>
          <div class="first-line"></div>
        </div>
        <div style="display: flex;flex-direction: column;margin-left: 25px">
          <p class="first-title-1">第二级：塑造品格</p>
          <p class="first-sub-title">熏陶培养目标意识、感恩能力、进取精神和善良品质等多方面<br>素质。</p>
        </div>
      </div>
      <div style="display: flex;flex-direction: row;margin-top: 30px;align-items: flex-start">
        <div style="display: flex;flex-direction: column;align-items: center;justify-content: center">
          <p class="first-index">3</p>
          <div class="first-line"></div>
        </div>
        <div style="display: flex;flex-direction: column;margin-left: 25px">
          <p class="first-title-1">第三级：深度思考</p>
          <p class="first-sub-title">和故事主角一起思考科学、思考成长、思考错误、思考人生。</p>
        </div>
      </div>
      <div style="display: flex;flex-direction: row;margin-top: 30px;align-items: flex-start">
        <div style="display: flex;flex-direction: column;align-items: center;justify-content: center">
          <p class="first-index">4</p>
        </div>
        <div style="display: flex;flex-direction: column;margin-left: 25px">
          <p class="first-title-1">第四级：更高格局</p>
          <p class="first-sub-title">站在更高的层次，观察时代印记、人间百态、历史脉络和自我<br>反思。</p>
        </div>
      </div>
    </div>
    <div class="line-shadow"></div>
    <div class="second-part">

      <p class="second-title" style="font-size: 36px;">第一级四大主题课程包</p>


      <div style="display: flex;flex-direction: row;align-items: center;justify-content: center;margin-top: 50px">
        <div class="second-item">
          <img src="../assets/part2_card1.png" style="width: 248px;height: 248px;object-fit: cover;border-radius: 4px">
          <p class="second-title">小人物大道理</p>
          <p class="second-desc">经典童话故事，启迪儿童对故事人<br>物的思考和感想</p>
        </div>
        <div class="second-item">
          <img src="../assets/part2_card2.png" style="width: 248px;height: 248px;object-fit: cover;border-radius: 4px">
          <p class="second-title">插上想象的翅膀</p>
          <p class="second-desc">在童话的王国中开启想象力的大门<br>和无限可能</p>
        </div>
        <div class="second-item">
          <img src="../assets/part2_card3.png" style="width: 248px;height: 248px;object-fit: cover;border-radius: 4px">
          <p class="second-title">智慧就是力量</p>
          <p class="second-desc">在遇到困难时学会动脑，用聪明才<br>智来跨越</p>
        </div>
        <div class="second-item">
          <img src="../assets/part2_card4.png" style="width: 248px;height: 248px;object-fit: cover;border-radius: 4px">
          <p class="second-title">关爱家人朋友</p>
          <p class="second-desc">知道家人和朋友是值得珍惜的，学<br>会如何与他们相处</p>
        </div>
      </div>


    </div>
    <div class="third-part">
      <p class="third-title">创作者说</p>
      <div class="third-content">
        <p class="third-title" style="font-size: 24px">关于音频小课</p>
        <p class="third-intro">我们尝试了市面上主流的课外学习音频平台，普遍感觉它们虽然内容丰富但是不成体系，结构过于零散很难坚持。我们想尝试一种兼<br>
          具便利性和计划性的解决方案，于是就有了<strong>“可能学园”</strong>和它的<strong>“音频小课”</strong>。欢迎将它作为课外学习的新选择。
        </p>
        <p class="third-title" style="font-size: 24px;margin-top: 50px">关于名著系列</p>
        <p class="third-intro">我们小时候很喜欢读名著，但是真正读完的只有几本。仔细想想很多时候就是因为没时间，没精力给搁置了。我一直在想，如果能有<br>
          办法让人快速阅读掌握名著故事的主线和精华就再好不过了。现在的我终于有机会可以去实现儿时的一个梦想，让他们能够在有限的<br>
          时间里领略到更多经典之美。
        </p>
      </div>


    </div>
    <div class="line-shadow"></div>
    <div class="four-part">
      <p class="four-title">用户反馈</p>
      <div style="display:flex;flex-direction: row;align-items: center;justify-content: center;margin-top: 50px">
        <img src="../assets/mother1.png" style="width: 316px;height: auto;object-fit: cover">
        <div style="margin-left: 40px">
          <p class="four-title" style="font-size: 24px">Alice妈妈</p>
          <p class="four-intro">
            <strong>可能学园有一个特别好的地方——每个音频最后都有设置互动，让Alice去回答</strong><br>
            <strong>这个问题</strong>。Alice答题的时候就会去主动思考，要是答错了她也会自己反思。 <strong>我觉</strong><br>
            <strong>得这种利用碎片化的学习，对她是比较好的</strong>。可能学园的内容长度在3分钟这样<br>
            也比较正好，Alice基本都能听完，很少会有放空走神的情况。
          </p>
        </div>
      </div>
      <div style="display:flex;flex-direction: row;align-items: center;justify-content: center;margin-top: 20px">
        <div>
          <p class="four-title" style="font-size: 24px">Danny妈妈</p>
          <p class="four-intro">
            Danny现在尤其喜欢听<strong>《八十天环游地球》</strong>。这种对话的形式比较生动，<strong>不像老<br>
            师在讲课的感觉</strong>。比起自己读名著，听名著对他来说难度更低，没有门槛和负<br>
            担。<strong>而且他的注意力专注在听上面</strong>，眼睛可以得到放松，也可以在碎片的时间里<br>
            一点一滴地积累知识。我支持把专业地事情交给专业的人做，让家长从繁重的辅<br>
            导中解脱出来。
          </p>
        </div>
        <img src="../assets/mother2.png" style="width: 316px;height: auto;object-fit: cover;margin-left: 40px">

      </div>


    </div>

    <div class="five-part">
      <div>
        <p class="five-title">优惠活动</p>
        <p class="five-title-1" style="margin-top: 30px">四大名著主题课程包可任选其一<span
            style="color: #626dae">优惠购买，最低可至2.5折，节省75%。</span><br>
          <span style="color: #626dae;font-size: 20px">下载可能学园即可获得优惠。</span>
        </p>

      </div>
      <img src="../assets/about_qrcode.png" style="margin-left: 300px">
    </div>

    <div style="padding: 100px 200px;text-align: right">
      <p style="color: #50555c;font-size: 16px;font-family: 'PingFang SC'">可能学园 Able Academy © 2022</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Collection"
}
</script>

<style scoped lang="scss">

.five-part {
  margin: 50px 0px;
  width: 100%;
  height: 424px;
  background-image: url("../assets/about_bg_1.jpeg");
  background-repeat: repeat;
  background-size: cover;
  background-color: transparent;
  background-position: 0px -97.1445px;
  background-attachment: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .five-title {
    color: #626dae;
    font-size: 36px;
    font-family: "Open Sans", "open_sans", "Lucida Grande", "Verdana", "Pingfang SC", "Pingfang TC", "Hiragino Sans GB", "Microsoft YaHei", Cardo, STSong, "Songti SC", SimSun, "PingFang SC", "Microsoft YaHei", 微软雅黑, STXihei, 华文细黑, serif;;;
  }

  .five-title-1 {
    color: #171a2c;
    font-size: 16px;
    line-height: 28px;
    font-family: "Open Sans", "open_sans", "Lucida Grande", "Verdana", "Pingfang SC", "Pingfang TC", "Hiragino Sans GB", "Microsoft YaHei", Cardo, STSong, "Songti SC", SimSun, "PingFang SC", "Microsoft YaHei", 微软雅黑, STXihei, 华文细黑, serif;;;
  }
}

.four-part {
  width: 100%;
  padding: 50px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .four-title {
    color: #626dae;
    font-size: 36px;
    font-family: "Open Sans", "open_sans", "Lucida Grande", "Verdana", "Pingfang SC", "Pingfang TC", "Hiragino Sans GB", "Microsoft YaHei", Cardo, STSong, "Songti SC", SimSun, "PingFang SC", "Microsoft YaHei", 微软雅黑, STXihei, 华文细黑, serif;;;
  }

  .four-intro {
    margin-top: 10px;
    color: #50555c;
    font-size: 16px;
    line-height: 28px;
    font-family: "Open Sans", "open_sans", "Lucida Grande", "Verdana", "Pingfang SC", "Pingfang TC", "Hiragino Sans GB", "Microsoft YaHei", Cardo, STSong, "Songti SC", SimSun, "PingFang SC", "Microsoft YaHei", 微软雅黑, STXihei, 华文细黑, serif;;;
  }
}

.third-part {
  width: 100%;
  padding: 50px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .third-content {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .third-title {
    color: #626dae;
    font-size: 36px;
    font-family: "Open Sans", "open_sans", "Lucida Grande", "Verdana", "Pingfang SC", "Pingfang TC", "Hiragino Sans GB", "Microsoft YaHei", Cardo, STSong, "Songti SC", SimSun, "PingFang SC", "Microsoft YaHei", 微软雅黑, STXihei, 华文细黑, serif;;;
  }

  .third-intro {
    margin-top: 10px;
    color: #50555c;
    font-size: 16px;
    line-height: 28px;
    font-family: "Open Sans", "open_sans", "Lucida Grande", "Verdana", "Pingfang SC", "Pingfang TC", "Hiragino Sans GB", "Microsoft YaHei", Cardo, STSong, "Songti SC", SimSun, "PingFang SC", "Microsoft YaHei", 微软雅黑, STXihei, 华文细黑, serif;;;
  }
}


.second-part {
  width: 100%;
  padding: 50px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .second-item {
    margin-right: 30px;
    display: flex;
    flex-direction: column;
  }

  .second-title {
    margin-top: 20px;
    color: #626dae;
    font-size: 24px;
    font-family: "Open Sans", "open_sans", "Lucida Grande", "Verdana", "Pingfang SC", "Pingfang TC", "Hiragino Sans GB", "Microsoft YaHei", Cardo, STSong, "Songti SC", SimSun, "PingFang SC", "Microsoft YaHei", 微软雅黑, STXihei, 华文细黑, serif;;;
  }

  .second-desc {
    margin-top: 15px;
    color: #50555c;
    font-size: 16px;
    line-height: 28px;
    font-family: "Open Sans", "open_sans", "Lucida Grande", "Verdana", "Pingfang SC", "Pingfang TC", "Hiragino Sans GB", "Microsoft YaHei", Cardo, STSong, "Songti SC", SimSun, "PingFang SC", "Microsoft YaHei", 微软雅黑, STXihei, 华文细黑, serif;;;
  }

}


.collection-container {
  width: 100%;
  height: 100%;
}

.collection-top {
  width: 100%;
  padding: 100px 0px 50px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .top-title {
    color: #626dae;
    font-size: 36px;
    font-family: "Open Sans", "open_sans", "Lucida Grande", "Verdana", "Pingfang SC", "Pingfang TC", "Hiragino Sans GB", "Microsoft YaHei", Cardo, STSong, "Songti SC", SimSun, "PingFang SC", "Microsoft YaHei", 微软雅黑, STXihei, 华文细黑, serif;;;
  }

  .top-sub-title {
    margin-top: 20px;
    color: #333333;
    font-size: 20px;
    font-family: "Open Sans", "open_sans", "Lucida Grande", "Verdana", "Pingfang SC", "Pingfang TC", "Hiragino Sans GB", "Microsoft YaHei", Cardo, STSong, "Songti SC", SimSun, "PingFang SC", "Microsoft YaHei", 微软雅黑, STXihei, 华文细黑, serif;;;
  }

  .top-item {
    width: 520px;
    text-align: center;
    background-color: #f3f3f3;
    padding: 20px 0px;
    color: #626dae;
    font-size: 24px;
    font-family: "Open Sans", "open_sans", "Lucida Grande", "Verdana", "Pingfang SC", "Pingfang TC", "Hiragino Sans GB", "Microsoft YaHei", Cardo, STSong, "Songti SC", SimSun, "PingFang SC", "Microsoft YaHei", 微软雅黑, STXihei, 华文细黑, serif;;;
  }
}

.line-shadow {
  margin: 60px auto 0;
  background: url('../assets/line-shadow.png') no-repeat 50%;
  height: 10px;
  background-size: contain;
  width: 1040px;
  max-width: 94%;
}

.first-part {
  width: 100%;
  padding: 100px 0px 0px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;


  .first-title {
    color: #626dae;
    font-size: 36px;
    font-family: "Open Sans", "open_sans", "Lucida Grande", "Verdana", "Pingfang SC", "Pingfang TC", "Hiragino Sans GB", "Microsoft YaHei", Cardo, STSong, "Songti SC", SimSun, "PingFang SC", "Microsoft YaHei", 微软雅黑, STXihei, 华文细黑, serif;;;
  }

  .first-title-1 {
    font-weight: bold;
    color: #626dae;
    font-size: 24px;
    font-family: "Open Sans", "open_sans", "Lucida Grande", "Verdana", "Pingfang SC", "Pingfang TC", "Hiragino Sans GB", "Microsoft YaHei", Cardo, STSong, "Songti SC", SimSun, "PingFang SC", "Microsoft YaHei", 微软雅黑, STXihei, 华文细黑, serif;;;
  }

  .first-sub-title {
    margin-top: 10px;
    color: #50555c;
    font-size: 16px;
    line-height: 28px;
    font-family: "Open Sans", "open_sans", "Lucida Grande", "Verdana", "Pingfang SC", "Pingfang TC", "Hiragino Sans GB", "Microsoft YaHei", Cardo, STSong, "Songti SC", SimSun, "PingFang SC", "Microsoft YaHei", 微软雅黑, STXihei, 华文细黑, serif;;;
  }

  .first-index {
    width: 54px;
    height: 54px;
    text-align: center;
    line-height: 54px;
    border: 1px solid #50555c;
    color: #50555c;
    font-size: 26px;
    font-family: "Open Sans", "open_sans", "Lucida Grande", "Verdana", "Pingfang SC", "Pingfang TC", "Hiragino Sans GB", "Microsoft YaHei", Cardo, STSong, "Songti SC", SimSun, "PingFang SC", "Microsoft YaHei", 微软雅黑, STXihei, 华文细黑, serif;;;
  }

  .first-line {
    margin-top: 20px;
    width: 1px;
    background-color: #50555c;
    opacity: .3;
    height: 60px;
  }
}


</style>
