<template>
  <div class="home_container">
    <div class="home_top">
      <img src="../assets/logo.png" style="width: 210px;height: auto;object-fit: contain">
      <div class="home-top-right">
        <router-link :class="selectedPackage?'title title-selected':'title'" :to="'/package'">名著主题课程包</router-link>
        <router-link :class="selectedAbout?'title title-selected':'title'" :to="'/about'">关于</router-link>
      </div>
    </div>
    <!--    <div style="width: 100%;height: 1px;background-color: #50555c"></div>-->
    <div style="overflow: auto;height: 90vh">
      <router-view/>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {}
  },
  computed: {
    selectedAbout: function () {
      let path = this.$route.path
      if (path.indexOf('about') >= 0) {
        return true
      }
      return false
    },
    selectedPackage: function () {
      let path = this.$route.path
      if (path.indexOf('package') >= 0) {
        return true
      }
      return false
    }
  },
  mounted() {

  },
  methods: {}
}
</script>

<style scoped lang="scss">

::-webkit-scrollbar {
  display: none;
}

.home_container {
  margin: 0 auto;
  max-width: 1920px;
  height: 100vh;
}

.home_top {
  height: 10vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-bottom-color: #666;
  border-bottom-width: 1px;
  border-bottom-style: solid;

  .home-top-right {
    margin-left: 600px;
    display: flex;
    flex-direction: row-reverse;
  }

  .title {
    text-decoration: none;
    cursor: pointer;
    margin-right: 20px;
    font-size: 16px;
    font-family: "Open Sans", "open_sans", "Lucida Grande", "Verdana", "Pingfang SC", "Pingfang TC", "Hiragino Sans GB", "Microsoft YaHei", Cardo, STSong, "Songti SC", SimSun, "PingFang SC", "Microsoft YaHei", 微软雅黑, STXihei, 华文细黑, serif;;
    color: #666666;
    //padding: 1px;
    //border: dashed transparent;
    //border-radius: 1px;

    &:hover {
      color: #626DAE;
      //border: dashed rgba(0, 0, 0, 0.5);
    }
  }

  .title-selected {
    color: #626DAE;
  }
}

//.home-content-1 {
//  width: 100%;
//  height: 328px;
//  background-image: url("../assets/bg2.jpg");
//  background-repeat: no-repeat;
//  background-size: 100% 842.062px;
//  background-color: transparent;
//  background-position: 0px -187.336px;
//  background-attachment: fixed;
//
//  .content {
//    background-color: rgba(5, 26, 52, 0.35);
//    width: 100%;
//    height: 100%;
//    display: flex;
//    flex-direction: column;
//    align-items: center;
//    justify-content: center;
//
//    .xiaocan {
//      color: white;
//      font-size: 72px;
//      font-family: "Open Sans", "open_sans", "Lucida Grande", "Verdana", "Pingfang SC", "Pingfang TC", "Hiragino Sans GB", "Microsoft YaHei", Cardo, STSong, "Songti SC", SimSun, "PingFang SC", "Microsoft YaHei", 微软雅黑, STXihei, 华文细黑, serif;;
//    }
//  }
//}
//
//
//.home-content-2 {
//  width: 100%;
//  height: 1052px;
//  background-image: url("../assets/bg1.jpg");
//  background-repeat: no-repeat;
//  background-color: transparent;
//  background-attachment: fixed;
//  background-size: 100% 851.4px;
//  background-position: -8.3px 64.5172px;
//
//  .content {
//    backdrop-filter: blur(0px);
//    background-color: rgba(5, 26, 52, 0.35);
//    width: 100%;
//    height: 100%;
//    display: flex;
//    flex-direction: row;
//    align-items: center;
//    justify-content: center;
//
//    .home-title-4 {
//      color: white;
//      font-size: 36px;
//      font-family: "Open Sans", "open_sans", "Lucida Grande", "Verdana", "Pingfang SC", "Pingfang TC", "Hiragino Sans GB", "Microsoft YaHei", Cardo, STSong, "Songti SC", SimSun, "PingFang SC", "Microsoft YaHei", 微软雅黑, STXihei, 华文细黑, serif;;
//    }
//
//    .down-load {
//      font-weight: bold;
//      border-radius: 4px;
//      box-shadow: 0 2px 0 #0e468c;
//      cursor: pointer;
//      background-color: #1258AF;
//      padding: 12px 18px;
//      color: white;
//      font-size: 16px;
//      font-family: "Open Sans", "open_sans", "Lucida Grande", "Verdana", "Pingfang SC", "Pingfang TC", "Hiragino Sans GB", "Microsoft YaHei", Cardo, STSong, "Songti SC", SimSun, "PingFang SC", "Microsoft YaHei", 微软雅黑, STXihei, 华文细黑, serif;;
//    }
//  }
//}
//
//.home-title {
//  color: #1258AF;
//  font-size: 29px;
//  font-family: "Open Sans", "open_sans", "Lucida Grande", "Verdana", "Pingfang SC", "Pingfang TC", "Hiragino Sans GB", "Microsoft YaHei", Cardo, STSong, "Songti SC", SimSun, "PingFang SC", "Microsoft YaHei", 微软雅黑, STXihei, 华文细黑, serif;;
//}
//
//.home-title-1 {
//  margin-top: 20px;
//  color: #222222;
//  font-size: 16px;
//  font-family: "Open Sans", "open_sans", "Lucida Grande", "Verdana", "Pingfang SC", "Pingfang TC", "Hiragino Sans GB", "Microsoft YaHei", Cardo, STSong, "Songti SC", SimSun, "PingFang SC", "Microsoft YaHei", 微软雅黑, STXihei, 华文细黑, serif;;
//}
//
//.home-title-3 {
//  margin-left: 80px;
//  color: #1258AF;
//  font-size: 32px;
//  font-family: "Open Sans", "open_sans", "Lucida Grande", "Verdana", "Pingfang SC", "Pingfang TC", "Hiragino Sans GB", "Microsoft YaHei", Cardo, STSong, "Songti SC", SimSun, "PingFang SC", "Microsoft YaHei", 微软雅黑, STXihei, 华文细黑, serif;;
//}
//
//
//.bottom {
//  line-height: 48px;
//  text-align: center;
//  width: 100%;
//  padding: 100px 0px;
//  font-size: 16px;
//  color: #50555c;
//  font-family: "Open Sans", "open_sans", "Lucida Grande", "Verdana", "Pingfang SC", "Pingfang TC", "Hiragino Sans GB", "Microsoft YaHei", Cardo, STSong, "Songti SC", SimSun, "PingFang SC", "Microsoft YaHei", 微软雅黑, STXihei, 华文细黑, serif;;
//
//}

</style>
